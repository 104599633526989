import HOG from "../Resources/HOG.png"
import BP from "../Resources/BP.png"

function Projects() {
    return <section>
        <h2>Projects</h2>
        <div className="app__projects">
            <div className="app__projects__card">
                <h3>House of Games</h3>
                <p>Board game review site with functionality to view and sort reviews, and select reviews by category. Users can post reviews and comments (and vote on others). Users can delete their own comments. Built using an express.js backend and a React frontend. Tested with jest.</p>
                <a href="https://gjhouseofgames.netlify.app/" target="blank"><img src={HOG} alt="House Of Games"/></a>
                <a href="https://github.com/gareth5james/GJ-nc-games-BE" target="blank" className="projects__link">Backend</a>
                <a href="https://github.com/gareth5james/GJ-nc-games-FE" target="blank" className="projects__link">Frontend</a>
            </div>
        </div>
        <div className="app__projects">
            <div className="app__projects__card">
                <h3>Bebington Podiatry</h3>
                <p>React website for a local podiatry practice.</p>
                <a href="https://www.bebingtonpodiatry.co.uk/" target="blank"><img src={BP} alt="House Of Games"/></a>
            </div>
        </div>
    </section>
}

export default Projects;