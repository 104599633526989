import "./App.css";

import Header from "./Components/Header";
import Home from "./Components/Home";
import Contact from "./Components/Contact";

import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="app">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </div>
  );
}

export default App;
