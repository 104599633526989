import About from "./About.jsx"
import Skills from "./Skills.jsx";
import Projects from "./Projects.jsx";

function Home() {
    return <main>
        <About id="about" className="app__home__mainCard"/>
        <Skills id="skills" className="app__home__mainCard"/>
        <Projects id="projects" className="app__home__mainCard"/>
    </main>
}

export default Home;