import JS from "../Resources/JS.png"
import HTML5 from "../Resources/HTML5.png"
import CSS3 from "../Resources/CSS3.png"
import React from "../Resources/React.png"
import Git from "../Resources/Git.png"
import Github from "../Resources/Github.png"
import Jest from "../Resources/Jest.png"
import Postgresql from "../Resources/Postgres.png"
import Node from "../Resources/Node.png"

function Skills() {
    return <section>
<h2>Top Skills</h2>
    <div className="app__skills">
        <div className="app__skills__container">
            <div className="app__skills__card"><img src={JS} alt="JavaScript"/></div>
            <div className="app__skills__card"><img src={HTML5} alt="HTML5"/></div>
            <div className="app__skills__card"><img src={CSS3} alt="CSS3"/></div>
        </div>
        <div className="app__skills__container">
            <div className="app__skills__card"><img src={React} alt="React"/></div>
            <div className="app__skills__card"><img src={Git} alt="Git"/></div>
            <div className="app__skills__card"><img src={Github} alt="GitHub"/></div>
        </div>
        <div className="app__skills__container">
            <div className="app__skills__card"><img src={Jest} alt="Jest"/></div>    
            <div className="app__skills__card"><img src={Postgresql} alt="SQL"/></div>
            <div className="app__skills__card"><img src={Node} alt="Node"/></div> 
        </div>
    </div>
    </section>
    
}

export default Skills;