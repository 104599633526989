function About() {
    return <section className="app__about">
        <h2>About Me</h2>
        <div className="p__container">
        <p>
        Fulfilling a long held ambition to learn a programming language, I recently graduated from Northcoders bootcamp
and am now seeking a career change into the software development field. I have a passion for problem solving,
and have found coding problems interesting thus far. Whilst enrolled on the course, I enjoyed the process of
learning, and I am keen to discover more languages as I move into the sector.
        </p>
        </div>
    </section>
}

export default About;