import Nav from "./Nav.jsx"
import { Link } from "react-router-dom"

function Header() {
    return <header className="app__header">
        <Link to="/"><h1>Gareth James</h1></Link>
        <Nav className="Nav"/>
    </header>
}

export default Header