import LI from "../Resources/LI.png"
import FB from "../Resources/Facebook.png"
import GH from "../Resources/Github.png"

function Contact() {
    return <main>
            <h2>Contact Me</h2>
            <div className="contact__form">
                <form name="contact" method="post">
                    <input type="hidden" name="form-name" value="contact" />
                <p><label htmlFor="name">Name</label> <br />
                    <input type="text" id="name" name="name" required />
                </p>
                <p><label htmlFor="email">Email</label> <br />
                    <input type="email" id="email" name="email" required />
                </p>
                <p><label htmlFor="message">Message</label> <br />
                    <textarea rows="5" cols="43" id="message" name="message" required></textarea>
                </p>
                <p>
                    <input type="submit" value="Submit message" />
                </p>
                </form>
            </div>
            <h3>Social Media</h3>
            <div className="socials">
                <a className="socials__link" href="https://www.linkedin.com/in/gareth-james-86125596/" target="blank"><img src={LI} alt="LinkedIn"/></a>
                <a className="socials__link" href="https://www.facebook.com/gazj85" target="blank"><img src={FB} alt="Facebook"/></a>
                <a className="socials__link" href="https://github.com/gareth5james" target="blank"><img src={GH} alt="Github"/></a>
            </div>
    </main>
}

export default Contact;